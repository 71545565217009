import { ApiService } from "./../../services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"]
})
export class CommentComponent implements OnInit {
  @Input("postId") id: string;
  data: any = {};
  form: FormGroup;
  constructor(private _fb: FormBuilder, private api: ApiService) {
    this.data["pageNum"] = 1;
    this.data["pageSize"] = 10;
    this.data["comments"] = [];
  }

  ngOnChanges() {
    this.getComments();
  }

  ngOnInit() {
    this.form = this._fb.group({
      name: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      comment: ["", Validators.required]
    });
  }

  onComment() {
    this.data["comments"] = [{ ...this.form.value }, ...this.data.comments];
    const data = { ...this.form.value, postId: this.id };
    this.form.reset();
    this.api.postData("api/comments", data).subscribe(res => {
      if (res["status"]) {
        this.getComments();
      }
    });
  }

  getComments(loadmore = false) {
    const { pageNum, pageSize } = this.data;
    const params = { postId: this.id, pageNum, pageSize };
    this.api.getData("api/comments", params).subscribe(res => {
      if (res["status"]) {
        if (loadmore) {
          this.data["comments"] = [...this.data["comments"], ...res["data"]];
        } else {
          this.data["comments"] = res["data"];
        }
        this.data["total"] = res["total"];
        this.data["totalPages"] = res["pages"];
      }
    });
  }

  loadMore() {
    this.data["pageNum"] = this.data["pageNum"] + 1;
    this.getComments(true);
  }
}
