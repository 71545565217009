import { ApiService } from "./../services/api.service";
import { Component } from "@angular/core";

@Component({
  templateUrl: "./favourite.html",
  styleUrls: ["./favourite.scss"]
})
export class FavouriteComponent {
  data: any = {};
  constructor(public api: ApiService) {}

  ngOnInit() {
    this.data["pageNum"] = 1;
    this.data["pageSize"] = 10;
    this.data["sort"] = true;
    this.getFavs();
  }

  getFavs() {
    this.data["loading"] = true;
    const data: any = {
      sort: this.data.sort ? "" : "",
      pageNum: this.data.pageNum,
      pageSize: this.data.pageSize
    };
    this.api.getData("api/service", data).subscribe(res => {
      this.data["loading"] = false;
      this.data["items"] = res["data"];
      this.data["total"] = res["total"];
      this.data["empty"] = this.data["items"].length ? false : true;
      this.createPagination();
    });
  }

  createPagination() {
    const arr = [];
    const count = +this.data.total / this.data.pageSize;
    for (let i = 1; i < count + 1; i++) {
      arr.push(i);
    }
    this.data["nav"] = arr;
  }

  paginate(ev, pageNum) {
    if (this.data["pageNum"] == pageNum) return;
    this.data["items"] = [];
    this.data["pageNum"] = pageNum;
    this.getFavs();
  }
}
