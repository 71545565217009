import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shorten"
})
export class ShortenPipe implements PipeTransform {
  transform(text: string, len?: number): string {
    if (typeof text === "string" && len > 0) {
      if (text.length > len) {
        return text.substring(0, len) + "..";
      } else {
        return text;
      }
    } else {
      return text;
    }
  }
}
