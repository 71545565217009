import { ApiService } from "./../../services/api.service";
import { Component, OnInit, Input } from "@angular/core";
@Component({
  selector: "app-recipe-box",
  templateUrl: "./recipe-box.html"
})
export class RecipeboxComponent implements OnInit {
  @Input() data: any = {};
  @Input() wrap: boolean = false;
  constructor(public api: ApiService) {}
  ngOnInit() {}

  onClick(item) {
    this.api.setCurrentItem(item);
    window.location.href = `view/${item._id}`;
  }
}
