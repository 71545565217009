import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "./../services/api.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-recipe",
  templateUrl: "./recipe.component.html",
  styleUrls: ["./recipe.component.scss"]
})
export class RecipeComponent implements OnInit {
  data: any = {};
  _sub: Subscription;
  constructor(public api: ApiService, private activRoutes: ActivatedRoute) {}

  ngOnInit() {
    this._sub = this.activRoutes.queryParams.subscribe(qParams => {
      this.data["q"] = qParams["q"] || "";
      this.data["catId"] = "";
      this.getCategories();
      this.getProducts();
    });
  }

  getCategories() {
    this.api.getData("api/categories", {}).subscribe(res => {
      if (res["status"]) {
        this.data["cat"] = res["data"];
      }
    });
  }

  getProducts() {
    this.data["items"] = [];
    this.data["loading"] = true;
    const data = { catId: this.data.catId, q: this.data.q };
    this.api.getData("api/common/search", data).subscribe(res => {
      if (res["status"]) {
        this.data["items"] = res["data"];
        this.data["loading"] = false;
      }
    });
  }
}
