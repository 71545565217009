import { FavouriteComponent } from "./favourite/favourite.component";
import { AboutComponent } from "./about/about.component";
import { ViewComponent } from "./view/view.component";
import { HomeComponent } from "./home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RecipeComponent } from "./recipe/recipe.component";
import { PrivacyComponent } from "./privacy/privacy";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "recipe", component: RecipeComponent },
  { path: "about", component: AboutComponent },
  { path: "favourites", component: FavouriteComponent },
  { path: "view/:id", component: ViewComponent },
  { path: "privacy", component: PrivacyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
