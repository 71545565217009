import { CommentComponent } from "./shared/comment/comment.component";
import { PrivacyComponent } from "./privacy/privacy";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule } from "@angular/common/http";
import { ViewComponent } from "./view/view.component";
import { RecipeComponent } from "./recipe/recipe.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RecipeboxComponent } from "./shared/recipe-box/recipe-box.component";
import { AboutComponent } from "./about/about.component";
import { FavouriteComponent } from "./favourite/favourite.component";
import { ShortenPipe } from "./shared/pipes/shorten";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ViewComponent,
    RecipeComponent,
    RecipeboxComponent,
    AboutComponent,
    FavouriteComponent,
    PrivacyComponent,
    ShortenPipe,
    CommentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
