import { ApiService } from "../services/api.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  data: any = {};
  constructor(public api: ApiService, private router: Router) {}

  ngOnInit() {
    this.data["pageNum"] = 1;
    this.data["pageCount"] = 1;
    this.data["pageSize"] = 12;
    this.data["sort"] = true;
    this.data["q"] = "";
    this.getRecipe();
  }

  ngAfterViewInit() {
    try {
      window["_mNHandle"].queue.push(function() {
        window["_mNDetails"].loadTag("875463237", "300x250", "875463237");
      });
    } catch (error) {}
  }

  getRecipe(type?) {
    if (!type) {
      this.data["loading"] = true;
    }
    const data: any = {
      catId: "",
      sort: this.data.sort ? true : "",
      pageNum: this.data.pageNum,
      pageSize: this.data.pageSize
    };
    this.api.getData("api/products", data).subscribe(res => {
      if (res["status"]) {
        console.log(res);
        if (!type) {
          this.data["slider"] = res["data"].slice(0, 4);
          setTimeout(() => {
            this.initSlider();
          }, 100);
        }
        this.data["items"] = res["data"];
        if (type) {
          this.data["innerLoading"] = false;
        }
        // setTimeout(() => {
        //   this.fixliststylebox();
        // }, 500);

        this.getPopularRecipes();

        this.data["total"] = res["total"];
        this.data["empty"] = this.data["items"].length ? false : true;
        this.createPagination();
      }
    });
  }

  initSlider() {
    const rsi = $("#homeSlider")
      .royalSlider({
        controlNavigation: "thumbnails",
        imageScaleMode: "fill",
        arrowsNav: false,
        arrowsNavHideOnTouch: true,
        slidesSpacing: 0,

        fullscreen: false,
        loop: false,

        thumbs: {
          firstMargin: false,
          paddingBottom: 0,
          spacing: 0
        },

        numImagesToPreload: 3,
        thumbsFirstMargin: false,

        keyboardNavEnabled: true,
        navigateByClick: false,
        fadeinLoadedSlide: true,
        transitionType: "fade",

        block: {
          fadeEffect: true,
          moveEffect: "top",
          delay: 0
        }
      })
      .data("royalSlider");
    $("#slider-next").click(function() {
      rsi.next();
    });
    $("#slider-prev").click(function() {
      rsi.prev();
    });
    this.data["loading"] = false;
  }

  fixliststylebox() {
    var winWidth = $(window).width();
    if (winWidth > 973) {
      $(".list-style .recipe-box-content").each(function() {
        var recipeHeight = $(this).outerHeight();
        $(this)
          .parent()
          .find(".thumbnail-holder")
          .height(recipeHeight);
      });
    } else {
      $(".thumbnail-holder").css({
        height: "auto"
      });
    }
    // var $container = $(".isotope");
    // $container.isotope({
    //   itemSelector: ".recipe-box, .isotope-box",
    //   layoutMode: "masonry"
    // });
  }

  async getPopularRecipes() {
    this.data["popular"] = await this.api.getPopularRecipes();
  }

  // navigate(ev, type?) {
  //   ev.preventDefault();
  //   if (type) {
  //     this.data["pageNum"] = this.data["pageNum"] + 1;
  //   } else {
  //     this.data["pageNum"] = this.data["pageNum"] - 1;
  //   }

  //   this.getRecipe("get");
  // }

  paginate(ev, pageNum) {
    if (this.data["pageNum"] == pageNum) return;
    this.data["items"] = [];
    this.data["innerLoading"] = true;
    this.data["pageNum"] = pageNum;
    this.getRecipe("get");
  }

  createPagination() {
    const arr = [];
    const count = +this.data.total / this.data.pageSize;
    for (let i = 1; i < count + 1; i++) {
      arr.push(i);
    }
    this.data["nav"] = arr;
  }

  gotoSearch() {
    this.router.navigateByUrl(`/recipe?q=${this.data.q}`);
  }

  getTime(data) {
    const timestamp = data.toString().substring(0, 8);
    return new Date(parseInt(timestamp, 16) * 1000);
  }
}
