import { ApiService } from "../services/api.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"]
})
export class ViewComponent implements OnInit {
  data: any = {};
  _sub: Subscription;

  constructor(private activRoute: ActivatedRoute, public api: ApiService) {}

  ngOnInit() {
    this.data["loading"] = true;
    this.data["url"] = window.location.href;
    this._sub = this.activRoute.params.subscribe(res => {
      this.data["id"] = res["id"];
      this.getProduct();
      this.data["query"] = "";
    });
  }

  getProduct() {
    this.data["loading"] = true;
    this.api
      .getData(`api/products/${this.data.id}`, {}, true)
      .subscribe(res => {
        if (res["status"]) {
          this.data["item"] = res["data"];
          this.data["image_2"] = this.data.item.images[1];
          setTimeout(() => {
            this.initSlider();
          }, 100);

          this.getPopularRecipes();
        }
      });
  }

  initSlider() {
    // Recipe Slider
    $(".recipeSlider").royalSlider({
      // autoScaleSliderHeight: 500,
      // autoHeight: true,
      // autoScaleSlider: false,
      // autoScaleSliderHeight: 800,
      imageScalePadding: 0,
      keyboardNavEnabled: true,
      navigateByClick: false,
      fadeinLoadedSlide: true,
      arrowsNavAutoHide: false,
      imageScaleMode: "fill",
      arrowsNav: true,
      slidesSpacing: 0,
      autoPlay: {
        // autoplay options go gere
        enabled: true,
        delay: 2000,
        pauseOnHover: false
      }
    });
    this.data["loading"] = false;
  }

  async getPopularRecipes() {
    this.data["popular"] = await this.api.getPopularRecipes(
      "",
      this.data.item._id
    );

    this.data["items"] = await this.api.getPopularRecipes(
      this.data.item.category._id,
      this.data.item._id
    );
  }
}
