import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  url: string = environment.url;
  currentItem: any = {};
  constructor(private http: HttpClient) {}

  getData(path: string, params: any, data = false) {
    return this.http.get(`${this.url}${path}`, {
      params: params
    });
  }

  postData(path: string, data) {
    return this.http.post(`${this.url}${path}`, data);
  }

  createImgUrl(url) {
    return `${this.url}${url}`;
  }

  getPopularRecipes(catId: string = "", removeId?) {
    return new Promise((resolve, reject) => {
      this.getData("api/products", { catId: catId }).subscribe(res => {
        const allData: Array<any> = res["data"];
        let data = [];
        if (removeId) {
          data = allData.filter(item => item._id !== removeId);
        } else {
          data = allData;
        }
        let sorted = data.sort((item1, item2) => item2.views - item1.views);
        sorted = sorted.slice(0, 3);
        resolve(sorted);
      });
    });
  }

  getTime(data) {
    const timestamp = data.toString().substring(0, 8);
    return new Date(parseInt(timestamp, 16) * 1000);
  }

  setCurrentItem(item) {
    sessionStorage.setItem("item", JSON.stringify(item));
  }

  getCurrentItem() {
    return JSON.parse(sessionStorage.getItem("item"));
  }

  createHeader(): HttpHeaders {
    console.log("header");
    const { previewImg, name } = this.getCurrentItem();
    const headers = new HttpHeaders({
      data: `${previewImg}|${name}`
    });
    return headers;
  }
}
