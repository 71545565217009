import { ApiService } from "./services/api.service";
import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "recipe-ui";
  isOpen = false;
  constructor() {}

  isActive(url) {
    if (window.location.pathname === url) {
      return "current" || "";
    }
  }
}
